import { Box } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import styled from '@mui/material/styles/styled';

import NavLink from '../nav-link/nav-link';
export const Wrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  transition: 'color 150ms ease-in-out',
  ':hover': {
    color: theme.palette.primary.main,
    '& .menu-list': { display: 'block' },
  },
}));

export const MenusContainer = styled(ListItem)(() => ({
  top: '100%',
  display: 'none',
  position: 'absolute',
  transform: 'translate(-30%, 0%)',
  height: 'auto',
  width: 'fit-content',
  zIndex: 999,
}));

export const MenuListItem = styled(ListItem)(({ theme }) => ({
  padding: '.5rem 1.5rem',
  ':hover': { backgroundColor: theme.palette.action.hover },
}));

export const StyledNavLink = styled(NavLink)({ transition: 'all 0.3s' });

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  boxShadow: '0px 0px 2.6px 0px rgba(0, 0, 0, 0.00) inset; ',
  background: 'rgba(220, 208, 255, 0.53)',
  height: '60px',
  padding: '1.5rem',
}));

export const StyledNav = styled('nav')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  gap: '1.5rem',
  flexWrap: 'nowrap',
  overflow: 'scroll',
}));

export const TimerNumber = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#5D4CC6',
  color: 'white',
  borderRadius: '4px',
  width: '1.5rem',
  height: '2rem',
  fontSize: '1rem',
}));
